/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AuthorizedPersonModel } from '../models/AuthorizedPersonModel';
import type { AuthorizedPersonModelRequest } from '../models/AuthorizedPersonModelRequest';
import type { BundleCreateModelRequest } from '../models/BundleCreateModelRequest';
import type { BundleReadModel } from '../models/BundleReadModel';
import type { BundleUpdateModelRequest } from '../models/BundleUpdateModelRequest';
import type { CalculatedPremiumReadModel } from '../models/CalculatedPremiumReadModel';
import type { Choice } from '../models/Choice';
import type { ClubFanvestorsCount } from '../models/ClubFanvestorsCount';
import type { FanVestorPortfolioFinancialInfo } from '../models/FanVestorPortfolioFinancialInfo';
import type { FanVestorPortfolioTotalPayout } from '../models/FanVestorPortfolioTotalPayout';
import type { OGWRedirect } from '../models/OGWRedirect';
import type { OGWRedirectRequest } from '../models/OGWRedirectRequest';
import type { PaginatedPortfolioListReadModelList } from '../models/PaginatedPortfolioListReadModelList';
import type { PaginatedTransferFanvestorList } from '../models/PaginatedTransferFanvestorList';
import type { PaginatedTransferReadModelList } from '../models/PaginatedTransferReadModelList';
import type { PaginatedUnlockedSocialPerkFanvestorModelList } from '../models/PaginatedUnlockedSocialPerkFanvestorModelList';
import type { PatchedAuthorizedPersonModelRequest } from '../models/PatchedAuthorizedPersonModelRequest';
import type { PatchedBundleUpdateModelRequest } from '../models/PatchedBundleUpdateModelRequest';
import type { PatchedPremiumUpdateModelRequest } from '../models/PatchedPremiumUpdateModelRequest';
import type { PatchedRewardEntryUpdateModelRequest } from '../models/PatchedRewardEntryUpdateModelRequest';
import type { PatchedRewardModifierUpdateModelRequest } from '../models/PatchedRewardModifierUpdateModelRequest';
import type { PatchedRewardUpdateModelRequest } from '../models/PatchedRewardUpdateModelRequest';
import type { PatchedSocialPerkStatusUpdateModelRequest } from '../models/PatchedSocialPerkStatusUpdateModelRequest';
import type { PatchedSocialPerkUpdateModelRequest } from '../models/PatchedSocialPerkUpdateModelRequest';
import type { PatchedTransactionUpdateModelRequest } from '../models/PatchedTransactionUpdateModelRequest';
import type { PatchedTransferSponsorUpdateModelRequest } from '../models/PatchedTransferSponsorUpdateModelRequest';
import type { PatchedTransferUpdateRequestRequest } from '../models/PatchedTransferUpdateRequestRequest';
import type { PremiumChoices } from '../models/PremiumChoices';
import type { PremiumCreateModelRequest } from '../models/PremiumCreateModelRequest';
import type { PremiumReadModel } from '../models/PremiumReadModel';
import type { PremiumUpdateModelRequest } from '../models/PremiumUpdateModelRequest';
import type { Ramp } from '../models/Ramp';
import type { RampRequest } from '../models/RampRequest';
import type { REConditionChoice } from '../models/REConditionChoice';
import type { RewardChoices } from '../models/RewardChoices';
import type { RewardCreateModelRequest } from '../models/RewardCreateModelRequest';
import type { RewardEntryCreateModelRequest } from '../models/RewardEntryCreateModelRequest';
import type { RewardEntryReadModel } from '../models/RewardEntryReadModel';
import type { RewardEntryUpdateModelRequest } from '../models/RewardEntryUpdateModelRequest';
import type { RewardModifierChoices } from '../models/RewardModifierChoices';
import type { RewardModifierCreateModelRequest } from '../models/RewardModifierCreateModelRequest';
import type { RewardModifierReadModel } from '../models/RewardModifierReadModel';
import type { RewardModifierUpdateModelRequest } from '../models/RewardModifierUpdateModelRequest';
import type { RewardReadModel } from '../models/RewardReadModel';
import type { RewardUpdateModelRequest } from '../models/RewardUpdateModelRequest';
import type { SocialPerkChoices } from '../models/SocialPerkChoices';
import type { SocialPerkCreateModelRequest } from '../models/SocialPerkCreateModelRequest';
import type { SocialPerkDocumentModel } from '../models/SocialPerkDocumentModel';
import type { SocialPerkReadModel } from '../models/SocialPerkReadModel';
import type { SocialPerkStatusUpdateModelRequest } from '../models/SocialPerkStatusUpdateModelRequest';
import type { SocialPerkUpdateModelRequest } from '../models/SocialPerkUpdateModelRequest';
import type { TransactionListReadModel } from '../models/TransactionListReadModel';
import type { TransactionReadModel } from '../models/TransactionReadModel';
import type { TransactionUpdateModelRequest } from '../models/TransactionUpdateModelRequest';
import type { TransferCreateRequestRequest } from '../models/TransferCreateRequestRequest';
import type { TransferCreateResponse } from '../models/TransferCreateResponse';
import type { TransferDocumentModel } from '../models/TransferDocumentModel';
import type { TransferReadModel } from '../models/TransferReadModel';
import type { TransferSponsorCreateModelRequest } from '../models/TransferSponsorCreateModelRequest';
import type { TransferSponsorReadModel } from '../models/TransferSponsorReadModel';
import type { TransferSponsorUpdateModelRequest } from '../models/TransferSponsorUpdateModelRequest';
import type { TransferUpdateRequestRequest } from '../models/TransferUpdateRequestRequest';
import type { TransferUpdateResponse } from '../models/TransferUpdateResponse';
import type { UnlockedSocialPerkReadModel } from '../models/UnlockedSocialPerkReadModel';
import type { URLData } from '../models/URLData';
import type { URLDataRequest } from '../models/URLDataRequest';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class TransfersService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * @returns PaginatedTransferReadModelList
     * @throws ApiError
     */
    public transfersList({
        club,
        fundingPeriodMax,
        fundingPeriodMaxGte,
        fundingPeriodMaxLte,
        fundingPeriodMin,
        fundingPeriodMinGte,
        fundingPeriodMinLte,
        fundingType,
        limit,
        offset,
        ordering,
        playerPosition,
        positionType,
        regions,
        search,
        sport,
        status,
    }: {
        /**
         * Multiple values may be separated by commas.
         */
        club?: Array<number>,
        fundingPeriodMax?: string,
        /**
         * funding_period_max_gte
         */
        fundingPeriodMaxGte?: string,
        /**
         * funding_period_max_lte
         */
        fundingPeriodMaxLte?: string,
        fundingPeriodMin?: string,
        /**
         * funding_period_min_gte
         */
        fundingPeriodMinGte?: string,
        /**
         * funding_period_min_lte
         */
        fundingPeriodMinLte?: string,
        /**
         * Multiple values may be separated by commas.
         */
        fundingType?: Array<string>,
        /**
         * Number of results to return per page.
         */
        limit?: number,
        /**
         * The initial index from which to return the results.
         */
        offset?: number,
        /**
         * Which field to use when ordering the results.
         */
        ordering?: string,
        /**
         * Multiple values may be separated by commas.
         */
        playerPosition?: Array<string>,
        /**
         * Multiple values may be separated by commas.
         */
        positionType?: Array<string>,
        /**
         * Multiple values may be separated by commas.
         */
        regions?: Array<string>,
        /**
         * A search term.
         */
        search?: string,
        /**
         * sport
         *
         * * `football` - Football
         * * `hockey` - Hockey
         */
        sport?: 'football' | 'hockey',
        /**
         * Multiple values may be separated by commas.
         */
        status?: Array<string>,
    }): CancelablePromise<PaginatedTransferReadModelList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/transfers/',
            query: {
                'club': club,
                'funding_period_max': fundingPeriodMax,
                'funding_period_max_gte': fundingPeriodMaxGte,
                'funding_period_max_lte': fundingPeriodMaxLte,
                'funding_period_min': fundingPeriodMin,
                'funding_period_min_gte': fundingPeriodMinGte,
                'funding_period_min_lte': fundingPeriodMinLte,
                'funding_type': fundingType,
                'limit': limit,
                'offset': offset,
                'ordering': ordering,
                'player_position': playerPosition,
                'position_type': positionType,
                'regions': regions,
                'search': search,
                'sport': sport,
                'status': status,
            },
        });
    }
    /**
     * @returns ClubFanvestorsCount
     * @throws ApiError
     */
    public transfersFanvestorsCountRetrieve({
        clubPk,
    }: {
        clubPk: number,
    }): CancelablePromise<ClubFanvestorsCount> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/transfers/{club_pk}/fanvestors-count/',
            path: {
                'club_pk': clubPk,
            },
        });
    }
    /**
     * @returns TransferReadModel
     * @throws ApiError
     */
    public transfersRetrieve({
        transferPk,
    }: {
        transferPk: number,
    }): CancelablePromise<TransferReadModel> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/transfers/{transfer_pk}/',
            path: {
                'transfer_pk': transferPk,
            },
        });
    }
    /**
     * @returns AuthorizedPersonModel
     * @throws ApiError
     */
    public transfersAuthorizedPersonsList({
        transferPk,
    }: {
        transferPk: number,
    }): CancelablePromise<Array<AuthorizedPersonModel>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/transfers/{transfer_pk}/authorized-persons/',
            path: {
                'transfer_pk': transferPk,
            },
        });
    }
    /**
     * @returns void
     * @throws ApiError
     */
    public transfersAuthorizedPersonsDeleteDestroy({
        authorizedPersonPk,
        transferPk,
    }: {
        authorizedPersonPk: number,
        transferPk: number,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/transfers/{transfer_pk}/authorized-persons/{authorized_person_pk}/delete/',
            path: {
                'authorized_person_pk': authorizedPersonPk,
                'transfer_pk': transferPk,
            },
        });
    }
    /**
     * @returns AuthorizedPersonModel
     * @throws ApiError
     */
    public transfersAuthorizedPersonsUpdateUpdate({
        authorizedPersonPk,
        transferPk,
        requestBody,
    }: {
        authorizedPersonPk: number,
        transferPk: number,
        requestBody: AuthorizedPersonModelRequest,
    }): CancelablePromise<AuthorizedPersonModel> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/transfers/{transfer_pk}/authorized-persons/{authorized_person_pk}/update/',
            path: {
                'authorized_person_pk': authorizedPersonPk,
                'transfer_pk': transferPk,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @returns AuthorizedPersonModel
     * @throws ApiError
     */
    public transfersAuthorizedPersonsUpdatePartialUpdate({
        authorizedPersonPk,
        transferPk,
        requestBody,
    }: {
        authorizedPersonPk: number,
        transferPk: number,
        requestBody?: PatchedAuthorizedPersonModelRequest,
    }): CancelablePromise<AuthorizedPersonModel> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/api/transfers/{transfer_pk}/authorized-persons/{authorized_person_pk}/update/',
            path: {
                'authorized_person_pk': authorizedPersonPk,
                'transfer_pk': transferPk,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @returns AuthorizedPersonModel
     * @throws ApiError
     */
    public transfersAuthorizedPersonsCreateCreate({
        transferPk,
        requestBody,
    }: {
        transferPk: number,
        requestBody: AuthorizedPersonModelRequest,
    }): CancelablePromise<AuthorizedPersonModel> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/transfers/{transfer_pk}/authorized-persons/create/',
            path: {
                'transfer_pk': transferPk,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @returns BundleReadModel
     * @throws ApiError
     */
    public transfersBundlesRetrieve({
        bundlePk,
        transferPk,
    }: {
        bundlePk: number,
        transferPk: number,
    }): CancelablePromise<BundleReadModel> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/transfers/{transfer_pk}/bundles/{bundle_pk}/',
            path: {
                'bundle_pk': bundlePk,
                'transfer_pk': transferPk,
            },
        });
    }
    /**
     * @returns void
     * @throws ApiError
     */
    public transfersBundlesDeleteDestroy({
        bundlePk,
        transferPk,
    }: {
        bundlePk: number,
        transferPk: number,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/transfers/{transfer_pk}/bundles/{bundle_pk}/delete/',
            path: {
                'bundle_pk': bundlePk,
                'transfer_pk': transferPk,
            },
        });
    }
    /**
     * @returns BundleReadModel
     * @throws ApiError
     */
    public transfersBundlesUpdateUpdate({
        bundlePk,
        transferPk,
        requestBody,
    }: {
        bundlePk: number,
        transferPk: number,
        requestBody?: BundleUpdateModelRequest,
    }): CancelablePromise<BundleReadModel> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/transfers/{transfer_pk}/bundles/{bundle_pk}/update/',
            path: {
                'bundle_pk': bundlePk,
                'transfer_pk': transferPk,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @returns BundleReadModel
     * @throws ApiError
     */
    public transfersBundlesUpdatePartialUpdate({
        bundlePk,
        transferPk,
        requestBody,
    }: {
        bundlePk: number,
        transferPk: number,
        requestBody?: PatchedBundleUpdateModelRequest,
    }): CancelablePromise<BundleReadModel> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/api/transfers/{transfer_pk}/bundles/{bundle_pk}/update/',
            path: {
                'bundle_pk': bundlePk,
                'transfer_pk': transferPk,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @returns BundleReadModel
     * @throws ApiError
     */
    public transfersBundlesCreateCreate({
        transferPk,
        requestBody,
    }: {
        transferPk: number,
        requestBody: BundleCreateModelRequest,
    }): CancelablePromise<BundleReadModel> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/transfers/{transfer_pk}/bundles/create/',
            path: {
                'transfer_pk': transferPk,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Mixin to validate Transfer objects before performing blockchain operations
     * @returns any No response body
     * @throws ApiError
     */
    public transfersClaimReservationsCreate({
        transferPk,
        txhash,
    }: {
        transferPk: number,
        /**
         * Transaction hash
         */
        txhash: string,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/transfers/{transfer_pk}/claim-reservations/',
            path: {
                'transfer_pk': transferPk,
            },
            query: {
                'txhash': txhash,
            },
        });
    }
    /**
     * Used to check obj permissions when creating Transfer-related objects
     * @returns any No response body
     * @throws ApiError
     */
    public transfersConfirmSmartContractCreate({
        transferPk,
        txhash,
    }: {
        transferPk: number,
        /**
         * Transaction hash
         */
        txhash: string,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/transfers/{transfer_pk}/confirm-smart-contract/',
            path: {
                'transfer_pk': transferPk,
            },
            query: {
                'txhash': txhash,
            },
        });
    }
    /**
     * Used to check obj permissions when creating Transfer-related objects
     * @returns URLData
     * @throws ApiError
     */
    public transfersCreateSmartContractCreate({
        redirectPath,
        transferPk,
        requestBody,
    }: {
        /**
         * Redirect path to be appended to the base URL
         */
        redirectPath: string,
        transferPk: number,
        requestBody: URLDataRequest,
    }): CancelablePromise<URLData> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/transfers/{transfer_pk}/create-smart-contract/',
            path: {
                'transfer_pk': transferPk,
            },
            query: {
                'redirect_path': redirectPath,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Used to check obj permissions when creating Transfer-related objects
     * @returns void
     * @throws ApiError
     */
    public transfersDeleteDestroy({
        transferPk,
    }: {
        transferPk: number,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/transfers/{transfer_pk}/delete/',
            path: {
                'transfer_pk': transferPk,
            },
        });
    }
    /**
     * @returns TransferDocumentModel
     * @throws ApiError
     */
    public transfersDocumentsClubAgreementRetrieve({
        transferPk,
    }: {
        transferPk: number,
    }): CancelablePromise<TransferDocumentModel> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/transfers/{transfer_pk}/documents/club-agreement/',
            path: {
                'transfer_pk': transferPk,
            },
        });
    }
    /**
     * @returns TransferDocumentModel
     * @throws ApiError
     */
    public transfersDocumentsClubFanvestorTransferAgreementRetrieve({
        transferPk,
        user,
    }: {
        transferPk: number,
        user?: number,
    }): CancelablePromise<TransferDocumentModel> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/transfers/{transfer_pk}/documents/club-fanvestor-transfer-agreement/',
            path: {
                'transfer_pk': transferPk,
            },
            query: {
                'user': user,
            },
        });
    }
    /**
     * @returns TransferDocumentModel
     * @throws ApiError
     */
    public transfersDocumentsClubTransferAgreementPreviewRetrieve({
        transferPk,
    }: {
        transferPk: number,
    }): CancelablePromise<TransferDocumentModel> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/transfers/{transfer_pk}/documents/club-transfer-agreement-preview/',
            path: {
                'transfer_pk': transferPk,
            },
        });
    }
    /**
     * @returns PaginatedTransferFanvestorList
     * @throws ApiError
     */
    public transfersFanvestorsList({
        transferPk,
        limit,
        offset,
        ordering,
    }: {
        transferPk: number,
        /**
         * Number of results to return per page.
         */
        limit?: number,
        /**
         * The initial index from which to return the results.
         */
        offset?: number,
        /**
         * Which field to use when ordering the results.
         */
        ordering?: string,
    }): CancelablePromise<PaginatedTransferFanvestorList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/transfers/{transfer_pk}/fanvestors/',
            path: {
                'transfer_pk': transferPk,
            },
            query: {
                'limit': limit,
                'offset': offset,
                'ordering': ordering,
            },
        });
    }
    /**
     * @returns binary
     * @throws ApiError
     */
    public transfersFanvestorsExportRetrieve({
        transferPk,
    }: {
        transferPk: number,
    }): CancelablePromise<Blob> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/transfers/{transfer_pk}/fanvestors/export/',
            path: {
                'transfer_pk': transferPk,
            },
        });
    }
    /**
     * @returns CalculatedPremiumReadModel
     * @throws ApiError
     */
    public transfersPremiumsList({
        transferPk,
    }: {
        transferPk: number,
    }): CancelablePromise<Array<CalculatedPremiumReadModel>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/transfers/{transfer_pk}/premiums/',
            path: {
                'transfer_pk': transferPk,
            },
        });
    }
    /**
     * Used to check obj permissions when creating Transfer-related objects
     * @returns void
     * @throws ApiError
     */
    public transfersPremiumsDeleteDestroy({
        premiumPk,
        transferPk,
    }: {
        premiumPk: number,
        transferPk: number,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/transfers/{transfer_pk}/premiums/{premium_pk}/delete/',
            path: {
                'premium_pk': premiumPk,
                'transfer_pk': transferPk,
            },
        });
    }
    /**
     * Used to check obj permissions when creating Transfer-related objects
     * @returns PremiumReadModel
     * @throws ApiError
     */
    public transfersPremiumsUpdateUpdate({
        premiumPk,
        transferPk,
        requestBody,
    }: {
        premiumPk: number,
        transferPk: number,
        requestBody?: PremiumUpdateModelRequest,
    }): CancelablePromise<PremiumReadModel> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/transfers/{transfer_pk}/premiums/{premium_pk}/update/',
            path: {
                'premium_pk': premiumPk,
                'transfer_pk': transferPk,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Used to check obj permissions when creating Transfer-related objects
     * @returns PremiumReadModel
     * @throws ApiError
     */
    public transfersPremiumsUpdatePartialUpdate({
        premiumPk,
        transferPk,
        requestBody,
    }: {
        premiumPk: number,
        transferPk: number,
        requestBody?: PatchedPremiumUpdateModelRequest,
    }): CancelablePromise<PremiumReadModel> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/api/transfers/{transfer_pk}/premiums/{premium_pk}/update/',
            path: {
                'premium_pk': premiumPk,
                'transfer_pk': transferPk,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Used to check obj permissions when creating Transfer-related objects
     * @returns PremiumReadModel
     * @throws ApiError
     */
    public transfersPremiumsCreateCreate({
        transferPk,
        requestBody,
    }: {
        transferPk: number,
        requestBody: PremiumCreateModelRequest,
    }): CancelablePromise<PremiumReadModel> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/transfers/{transfer_pk}/premiums/create/',
            path: {
                'transfer_pk': transferPk,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @returns CalculatedPremiumReadModel
     * @throws ApiError
     */
    public transfersPremiumsPreviewList({
        transferPk,
    }: {
        transferPk: number,
    }): CancelablePromise<Array<CalculatedPremiumReadModel>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/transfers/{transfer_pk}/premiums/preview/',
            path: {
                'transfer_pk': transferPk,
            },
        });
    }
    /**
     * Mixin to validate Transfer objects before performing blockchain operations
     * @returns any No response body
     * @throws ApiError
     */
    public transfersRefundReservationsCreate({
        transferPk,
        txhash,
    }: {
        transferPk: number,
        /**
         * Transaction hash
         */
        txhash: string,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/transfers/{transfer_pk}/refund-reservations/',
            path: {
                'transfer_pk': transferPk,
            },
            query: {
                'txhash': txhash,
            },
        });
    }
    /**
     * Used to check obj permissions when creating Transfer-related objects
     * @returns void
     * @throws ApiError
     */
    public transfersRewardModifiersDeleteDestroy({
        rewardModifierPk,
        transferPk,
    }: {
        rewardModifierPk: number,
        transferPk: number,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/transfers/{transfer_pk}/reward-modifiers/{reward_modifier_pk}/delete/',
            path: {
                'reward_modifier_pk': rewardModifierPk,
                'transfer_pk': transferPk,
            },
        });
    }
    /**
     * Used to check obj permissions when creating Transfer-related objects
     * @returns RewardModifierReadModel
     * @throws ApiError
     */
    public transfersRewardModifiersUpdateUpdate({
        rewardModifierPk,
        transferPk,
        requestBody,
    }: {
        rewardModifierPk: number,
        transferPk: number,
        requestBody?: RewardModifierUpdateModelRequest,
    }): CancelablePromise<RewardModifierReadModel> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/transfers/{transfer_pk}/reward-modifiers/{reward_modifier_pk}/update/',
            path: {
                'reward_modifier_pk': rewardModifierPk,
                'transfer_pk': transferPk,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Used to check obj permissions when creating Transfer-related objects
     * @returns RewardModifierReadModel
     * @throws ApiError
     */
    public transfersRewardModifiersUpdatePartialUpdate({
        rewardModifierPk,
        transferPk,
        requestBody,
    }: {
        rewardModifierPk: number,
        transferPk: number,
        requestBody?: PatchedRewardModifierUpdateModelRequest,
    }): CancelablePromise<RewardModifierReadModel> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/api/transfers/{transfer_pk}/reward-modifiers/{reward_modifier_pk}/update/',
            path: {
                'reward_modifier_pk': rewardModifierPk,
                'transfer_pk': transferPk,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Used to check obj permissions when creating Transfer-related objects
     * @returns RewardModifierReadModel
     * @throws ApiError
     */
    public transfersRewardModifiersCreateCreate({
        transferPk,
        requestBody,
    }: {
        transferPk: number,
        requestBody: RewardModifierCreateModelRequest,
    }): CancelablePromise<RewardModifierReadModel> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/transfers/{transfer_pk}/reward-modifiers/create/',
            path: {
                'transfer_pk': transferPk,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Used to check obj permissions when creating Transfer-related objects
     * @returns void
     * @throws ApiError
     */
    public transfersRewardsDeleteDestroy({
        rewardPk,
        transferPk,
    }: {
        rewardPk: number,
        transferPk: number,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/transfers/{transfer_pk}/rewards/{reward_pk}/delete/',
            path: {
                'reward_pk': rewardPk,
                'transfer_pk': transferPk,
            },
        });
    }
    /**
     * Used to check obj permissions when creating Transfer-related objects
     * @returns void
     * @throws ApiError
     */
    public transfersRewardsRewardEntriesDeleteDestroy({
        rewardEntryPk,
        rewardPk,
        transferPk,
    }: {
        rewardEntryPk: number,
        rewardPk: number,
        transferPk: number,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/transfers/{transfer_pk}/rewards/{reward_pk}/reward-entries/{reward_entry_pk}/delete/',
            path: {
                'reward_entry_pk': rewardEntryPk,
                'reward_pk': rewardPk,
                'transfer_pk': transferPk,
            },
        });
    }
    /**
     * Used to check obj permissions when creating Transfer-related objects
     * @returns RewardEntryReadModel
     * @throws ApiError
     */
    public transfersRewardsRewardEntriesUpdateUpdate({
        rewardEntryPk,
        rewardPk,
        transferPk,
        requestBody,
    }: {
        rewardEntryPk: number,
        rewardPk: number,
        transferPk: number,
        requestBody?: RewardEntryUpdateModelRequest,
    }): CancelablePromise<RewardEntryReadModel> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/transfers/{transfer_pk}/rewards/{reward_pk}/reward-entries/{reward_entry_pk}/update/',
            path: {
                'reward_entry_pk': rewardEntryPk,
                'reward_pk': rewardPk,
                'transfer_pk': transferPk,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Used to check obj permissions when creating Transfer-related objects
     * @returns RewardEntryReadModel
     * @throws ApiError
     */
    public transfersRewardsRewardEntriesUpdatePartialUpdate({
        rewardEntryPk,
        rewardPk,
        transferPk,
        requestBody,
    }: {
        rewardEntryPk: number,
        rewardPk: number,
        transferPk: number,
        requestBody?: PatchedRewardEntryUpdateModelRequest,
    }): CancelablePromise<RewardEntryReadModel> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/api/transfers/{transfer_pk}/rewards/{reward_pk}/reward-entries/{reward_entry_pk}/update/',
            path: {
                'reward_entry_pk': rewardEntryPk,
                'reward_pk': rewardPk,
                'transfer_pk': transferPk,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Used to check obj permissions when creating Transfer-related objects
     * @returns RewardEntryReadModel
     * @throws ApiError
     */
    public transfersRewardsRewardEntriesCreateCreate({
        rewardPk,
        transferPk,
        requestBody,
    }: {
        rewardPk: number,
        transferPk: number,
        requestBody: RewardEntryCreateModelRequest,
    }): CancelablePromise<RewardEntryReadModel> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/transfers/{transfer_pk}/rewards/{reward_pk}/reward-entries/create/',
            path: {
                'reward_pk': rewardPk,
                'transfer_pk': transferPk,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Used to check obj permissions when creating Transfer-related objects
     * @returns RewardReadModel
     * @throws ApiError
     */
    public transfersRewardsUpdateUpdate({
        rewardPk,
        transferPk,
        requestBody,
    }: {
        rewardPk: number,
        transferPk: number,
        requestBody?: RewardUpdateModelRequest,
    }): CancelablePromise<RewardReadModel> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/transfers/{transfer_pk}/rewards/{reward_pk}/update/',
            path: {
                'reward_pk': rewardPk,
                'transfer_pk': transferPk,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Used to check obj permissions when creating Transfer-related objects
     * @returns RewardReadModel
     * @throws ApiError
     */
    public transfersRewardsUpdatePartialUpdate({
        rewardPk,
        transferPk,
        requestBody,
    }: {
        rewardPk: number,
        transferPk: number,
        requestBody?: PatchedRewardUpdateModelRequest,
    }): CancelablePromise<RewardReadModel> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/api/transfers/{transfer_pk}/rewards/{reward_pk}/update/',
            path: {
                'reward_pk': rewardPk,
                'transfer_pk': transferPk,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Used to check obj permissions when creating Transfer-related objects
     * @returns RewardReadModel
     * @throws ApiError
     */
    public transfersRewardsCreateCreate({
        transferPk,
        requestBody,
    }: {
        transferPk: number,
        requestBody: RewardCreateModelRequest,
    }): CancelablePromise<RewardReadModel> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/transfers/{transfer_pk}/rewards/create/',
            path: {
                'transfer_pk': transferPk,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @returns UnlockedSocialPerkReadModel
     * @throws ApiError
     */
    public transfersSocialPerksList({
        transferPk,
    }: {
        transferPk: number,
    }): CancelablePromise<Array<UnlockedSocialPerkReadModel>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/transfers/{transfer_pk}/social-perks/',
            path: {
                'transfer_pk': transferPk,
            },
        });
    }
    /**
     * @returns SocialPerkReadModel
     * @throws ApiError
     */
    public transfersSocialPerksRetrieve({
        socialPerkPk,
        transferPk,
    }: {
        socialPerkPk: number,
        transferPk: number,
    }): CancelablePromise<SocialPerkReadModel> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/transfers/{transfer_pk}/social-perks/{social_perk_pk}/',
            path: {
                'social_perk_pk': socialPerkPk,
                'transfer_pk': transferPk,
            },
        });
    }
    /**
     * Used to check obj permissions when creating Transfer-related objects
     * @returns void
     * @throws ApiError
     */
    public transfersSocialPerksDeleteDestroy({
        socialPerkPk,
        transferPk,
    }: {
        socialPerkPk: number,
        transferPk: number,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/transfers/{transfer_pk}/social-perks/{social_perk_pk}/delete/',
            path: {
                'social_perk_pk': socialPerkPk,
                'transfer_pk': transferPk,
            },
        });
    }
    /**
     * @returns PaginatedUnlockedSocialPerkFanvestorModelList
     * @throws ApiError
     */
    public transfersSocialPerksFanvestorsList({
        socialPerkPk,
        transferPk,
        limit,
        offset,
        ordering,
    }: {
        socialPerkPk: number,
        transferPk: number,
        /**
         * Number of results to return per page.
         */
        limit?: number,
        /**
         * The initial index from which to return the results.
         */
        offset?: number,
        /**
         * Which field to use when ordering the results.
         */
        ordering?: string,
    }): CancelablePromise<PaginatedUnlockedSocialPerkFanvestorModelList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/transfers/{transfer_pk}/social-perks/{social_perk_pk}/fanvestors/',
            path: {
                'social_perk_pk': socialPerkPk,
                'transfer_pk': transferPk,
            },
            query: {
                'limit': limit,
                'offset': offset,
                'ordering': ordering,
            },
        });
    }
    /**
     * @returns SocialPerkDocumentModel
     * @throws ApiError
     */
    public transfersSocialPerksFanvestorsXlsxRetrieve({
        socialPerkPk,
        transferPk,
    }: {
        socialPerkPk: number,
        transferPk: number,
    }): CancelablePromise<SocialPerkDocumentModel> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/transfers/{transfer_pk}/social-perks/{social_perk_pk}/fanvestors-xlsx/',
            path: {
                'social_perk_pk': socialPerkPk,
                'transfer_pk': transferPk,
            },
        });
    }
    /**
     * @returns SocialPerkReadModel
     * @throws ApiError
     */
    public transfersSocialPerksStatusUpdateUpdate({
        socialPerkPk,
        transferPk,
        requestBody,
    }: {
        socialPerkPk: number,
        transferPk: number,
        requestBody?: SocialPerkStatusUpdateModelRequest,
    }): CancelablePromise<SocialPerkReadModel> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/transfers/{transfer_pk}/social-perks/{social_perk_pk}/status-update/',
            path: {
                'social_perk_pk': socialPerkPk,
                'transfer_pk': transferPk,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @returns SocialPerkReadModel
     * @throws ApiError
     */
    public transfersSocialPerksStatusUpdatePartialUpdate({
        socialPerkPk,
        transferPk,
        requestBody,
    }: {
        socialPerkPk: number,
        transferPk: number,
        requestBody?: PatchedSocialPerkStatusUpdateModelRequest,
    }): CancelablePromise<SocialPerkReadModel> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/api/transfers/{transfer_pk}/social-perks/{social_perk_pk}/status-update/',
            path: {
                'social_perk_pk': socialPerkPk,
                'transfer_pk': transferPk,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Used to check obj permissions when creating Transfer-related objects
     * @returns SocialPerkReadModel
     * @throws ApiError
     */
    public transfersSocialPerksUpdateUpdate({
        socialPerkPk,
        transferPk,
        requestBody,
    }: {
        socialPerkPk: number,
        transferPk: number,
        requestBody?: SocialPerkUpdateModelRequest,
    }): CancelablePromise<SocialPerkReadModel> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/transfers/{transfer_pk}/social-perks/{social_perk_pk}/update/',
            path: {
                'social_perk_pk': socialPerkPk,
                'transfer_pk': transferPk,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Used to check obj permissions when creating Transfer-related objects
     * @returns SocialPerkReadModel
     * @throws ApiError
     */
    public transfersSocialPerksUpdatePartialUpdate({
        socialPerkPk,
        transferPk,
        requestBody,
    }: {
        socialPerkPk: number,
        transferPk: number,
        requestBody?: PatchedSocialPerkUpdateModelRequest,
    }): CancelablePromise<SocialPerkReadModel> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/api/transfers/{transfer_pk}/social-perks/{social_perk_pk}/update/',
            path: {
                'social_perk_pk': socialPerkPk,
                'transfer_pk': transferPk,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Used to check obj permissions when creating Transfer-related objects
     * @returns SocialPerkReadModel
     * @throws ApiError
     */
    public transfersSocialPerksCreateCreate({
        transferPk,
        requestBody,
    }: {
        transferPk: number,
        requestBody: SocialPerkCreateModelRequest,
    }): CancelablePromise<SocialPerkReadModel> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/transfers/{transfer_pk}/social-perks/create/',
            path: {
                'transfer_pk': transferPk,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Used to check obj permissions when creating Transfer-related objects
     * @returns void
     * @throws ApiError
     */
    public transfersSponsorsDeleteDestroy({
        transferPk,
        transferSponsorPk,
    }: {
        transferPk: number,
        transferSponsorPk: number,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/transfers/{transfer_pk}/sponsors/{transfer_sponsor_pk}/delete/',
            path: {
                'transfer_pk': transferPk,
                'transfer_sponsor_pk': transferSponsorPk,
            },
        });
    }
    /**
     * Used to check obj permissions when creating Transfer-related objects
     * @returns TransferSponsorReadModel
     * @throws ApiError
     */
    public transfersSponsorsUpdateUpdate({
        transferPk,
        transferSponsorPk,
        requestBody,
    }: {
        transferPk: number,
        transferSponsorPk: number,
        requestBody?: TransferSponsorUpdateModelRequest,
    }): CancelablePromise<TransferSponsorReadModel> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/transfers/{transfer_pk}/sponsors/{transfer_sponsor_pk}/update/',
            path: {
                'transfer_pk': transferPk,
                'transfer_sponsor_pk': transferSponsorPk,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Used to check obj permissions when creating Transfer-related objects
     * @returns TransferSponsorReadModel
     * @throws ApiError
     */
    public transfersSponsorsUpdatePartialUpdate({
        transferPk,
        transferSponsorPk,
        requestBody,
    }: {
        transferPk: number,
        transferSponsorPk: number,
        requestBody?: PatchedTransferSponsorUpdateModelRequest,
    }): CancelablePromise<TransferSponsorReadModel> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/api/transfers/{transfer_pk}/sponsors/{transfer_sponsor_pk}/update/',
            path: {
                'transfer_pk': transferPk,
                'transfer_sponsor_pk': transferSponsorPk,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Used to check obj permissions when creating Transfer-related objects
     * @returns TransferSponsorReadModel
     * @throws ApiError
     */
    public transfersSponsorsCreateCreate({
        transferPk,
        requestBody,
    }: {
        transferPk: number,
        requestBody: TransferSponsorCreateModelRequest,
    }): CancelablePromise<TransferSponsorReadModel> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/transfers/{transfer_pk}/sponsors/create/',
            path: {
                'transfer_pk': transferPk,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Mixin to validate Transfer objects before performing blockchain operations
     * @returns URLData
     * @throws ApiError
     */
    public transfersStartRefundStageCreate({
        redirectPath,
        transferPk,
        requestBody,
    }: {
        /**
         * Redirect path to be appended to the base URL
         */
        redirectPath: string,
        transferPk: number,
        requestBody: URLDataRequest,
    }): CancelablePromise<URLData> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/transfers/{transfer_pk}/start-refund-stage/',
            path: {
                'transfer_pk': transferPk,
            },
            query: {
                'redirect_path': redirectPath,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Mixin to validate Transfer objects before performing blockchain operations
     * @returns URLData
     * @throws ApiError
     */
    public transfersStartTransferStageCreate({
        redirectPath,
        transferPk,
        requestBody,
    }: {
        /**
         * Redirect path to be appended to the base URL
         */
        redirectPath: string,
        transferPk: number,
        requestBody: URLDataRequest,
    }): CancelablePromise<URLData> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/transfers/{transfer_pk}/start-transfer-stage/',
            path: {
                'transfer_pk': transferPk,
            },
            query: {
                'redirect_path': redirectPath,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @returns TransactionListReadModel
     * @throws ApiError
     */
    public transfersTransactionsList({
        transferPk,
        status,
    }: {
        transferPk: number,
        /**
         * status
         *
         * * `WAITING_TO_BE_PROCESSED` - Waiting to be processed
         * * `IN_PROCESS` - In process
         * * `PROCESSED_SUCCESSFULLY` - Processed successfully
         * * `FAILED_TO_PROCESS` - Failed to process
         * * `FAILED_AMOUNT_MISMATCH` - Failed amount mismatch
         */
        status?: 'FAILED_AMOUNT_MISMATCH' | 'FAILED_TO_PROCESS' | 'IN_PROCESS' | 'PROCESSED_SUCCESSFULLY' | 'WAITING_TO_BE_PROCESSED' | null,
    }): CancelablePromise<Array<TransactionListReadModel>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/transfers/{transfer_pk}/transactions/',
            path: {
                'transfer_pk': transferPk,
            },
            query: {
                'status': status,
            },
        });
    }
    /**
     * @returns TransactionReadModel
     * @throws ApiError
     */
    public transfersTransactionsRetrieve({
        transactionPk,
        transferPk,
    }: {
        transactionPk: number,
        transferPk: number,
    }): CancelablePromise<TransactionReadModel> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/transfers/{transfer_pk}/transactions/{transaction_pk}/',
            path: {
                'transaction_pk': transactionPk,
                'transfer_pk': transferPk,
            },
        });
    }
    /**
     * @returns URLData
     * @throws ApiError
     */
    public transfersTransactionsConfirmCreate({
        redirectPath,
        transactionPk,
        transferPk,
        requestBody,
    }: {
        /**
         * Redirect path to be appended to the base URL
         */
        redirectPath: string,
        transactionPk: number,
        transferPk: number,
        requestBody: URLDataRequest,
    }): CancelablePromise<URLData> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/transfers/{transfer_pk}/transactions/{transaction_pk}/confirm/',
            path: {
                'transaction_pk': transactionPk,
                'transfer_pk': transferPk,
            },
            query: {
                'redirect_path': redirectPath,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @returns Ramp
     * @throws ApiError
     */
    public transfersTransactionsRampCreate({
        transactionPk,
        transferPk,
        requestBody,
    }: {
        transactionPk: number,
        transferPk: number,
        requestBody: RampRequest,
    }): CancelablePromise<Ramp> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/transfers/{transfer_pk}/transactions/{transaction_pk}/ramp/',
            path: {
                'transaction_pk': transactionPk,
                'transfer_pk': transferPk,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @returns OGWRedirect
     * @throws ApiError
     */
    public transfersTransactionsSuccessCreate({
        transactionPk,
        transferPk,
        requestBody,
    }: {
        transactionPk: number,
        transferPk: number,
        requestBody?: OGWRedirectRequest,
    }): CancelablePromise<OGWRedirect> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/transfers/{transfer_pk}/transactions/{transaction_pk}/success/',
            path: {
                'transaction_pk': transactionPk,
                'transfer_pk': transferPk,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @returns TransactionReadModel
     * @throws ApiError
     */
    public transfersTransactionsUpdateUpdate({
        transactionPk,
        transferPk,
        requestBody,
    }: {
        transactionPk: number,
        transferPk: number,
        requestBody: TransactionUpdateModelRequest,
    }): CancelablePromise<TransactionReadModel> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/transfers/{transfer_pk}/transactions/{transaction_pk}/update/',
            path: {
                'transaction_pk': transactionPk,
                'transfer_pk': transferPk,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @returns TransactionReadModel
     * @throws ApiError
     */
    public transfersTransactionsUpdatePartialUpdate({
        transactionPk,
        transferPk,
        requestBody,
    }: {
        transactionPk: number,
        transferPk: number,
        requestBody?: PatchedTransactionUpdateModelRequest,
    }): CancelablePromise<TransactionReadModel> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/api/transfers/{transfer_pk}/transactions/{transaction_pk}/update/',
            path: {
                'transaction_pk': transactionPk,
                'transfer_pk': transferPk,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @returns TransactionReadModel
     * @throws ApiError
     */
    public transfersTransactionsCreateCreate({
        transferPk,
    }: {
        transferPk: number,
    }): CancelablePromise<TransactionReadModel> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/transfers/{transfer_pk}/transactions/create/',
            path: {
                'transfer_pk': transferPk,
            },
        });
    }
    /**
     * @returns TransactionReadModel
     * @throws ApiError
     */
    public transfersTransactionsInProgressRetrieve({
        transferPk,
    }: {
        transferPk: number,
    }): CancelablePromise<TransactionReadModel> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/transfers/{transfer_pk}/transactions/in-progress/',
            path: {
                'transfer_pk': transferPk,
            },
        });
    }
    /**
     * Used to check obj permissions when creating Transfer-related objects
     * @returns TransferUpdateResponse
     * @throws ApiError
     */
    public transfersUpdateUpdate({
        transferPk,
        requestBody,
    }: {
        transferPk: number,
        requestBody?: TransferUpdateRequestRequest,
    }): CancelablePromise<TransferUpdateResponse> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/transfers/{transfer_pk}/update/',
            path: {
                'transfer_pk': transferPk,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Used to check obj permissions when creating Transfer-related objects
     * @returns TransferUpdateResponse
     * @throws ApiError
     */
    public transfersUpdatePartialUpdate({
        transferPk,
        requestBody,
    }: {
        transferPk: number,
        requestBody?: PatchedTransferUpdateRequestRequest,
    }): CancelablePromise<TransferUpdateResponse> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/api/transfers/{transfer_pk}/update/',
            path: {
                'transfer_pk': transferPk,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @returns Choice
     * @throws ApiError
     */
    public transfersChoicesPlayerPositionsList({
        sport,
    }: {
        sport: string,
    }): CancelablePromise<Array<Choice>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/transfers/choices/{sport}/player-positions/',
            path: {
                'sport': sport,
            },
        });
    }
    /**
     * @returns Choice
     * @throws ApiError
     */
    public transfersChoicesPositionTypesList({
        sport,
    }: {
        sport: string,
    }): CancelablePromise<Array<Choice>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/transfers/choices/{sport}/position-types/',
            path: {
                'sport': sport,
            },
        });
    }
    /**
     * @returns Choice
     * @throws ApiError
     */
    public transfersChoicesRegionsList({
        sport,
    }: {
        sport: string,
    }): CancelablePromise<Array<Choice>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/transfers/choices/{sport}/regions/',
            path: {
                'sport': sport,
            },
        });
    }
    /**
     * @returns TransferCreateResponse
     * @throws ApiError
     */
    public transfersCreateCreate({
        requestBody,
    }: {
        requestBody?: TransferCreateRequestRequest,
    }): CancelablePromise<TransferCreateResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/transfers/create/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @returns PaginatedPortfolioListReadModelList
     * @throws ApiError
     */
    public transfersFanvestorPortfolioList({
        club,
        fundingPeriodMax,
        fundingPeriodMaxGte,
        fundingPeriodMaxLte,
        fundingPeriodMin,
        fundingPeriodMinGte,
        fundingPeriodMinLte,
        fundingType,
        limit,
        offset,
        ordering,
        playerPosition,
        positionType,
        regions,
        search,
        sport,
        status,
        transferId,
    }: {
        /**
         * Multiple values may be separated by commas.
         */
        club?: Array<number>,
        fundingPeriodMax?: string,
        /**
         * funding_period_max_gte
         */
        fundingPeriodMaxGte?: string,
        /**
         * funding_period_max_lte
         */
        fundingPeriodMaxLte?: string,
        fundingPeriodMin?: string,
        /**
         * funding_period_min_gte
         */
        fundingPeriodMinGte?: string,
        /**
         * funding_period_min_lte
         */
        fundingPeriodMinLte?: string,
        /**
         * Multiple values may be separated by commas.
         */
        fundingType?: Array<string>,
        /**
         * Number of results to return per page.
         */
        limit?: number,
        /**
         * The initial index from which to return the results.
         */
        offset?: number,
        /**
         * Which field to use when ordering the results.
         */
        ordering?: string,
        /**
         * Multiple values may be separated by commas.
         */
        playerPosition?: Array<string>,
        /**
         * Multiple values may be separated by commas.
         */
        positionType?: Array<string>,
        /**
         * Multiple values may be separated by commas.
         */
        regions?: Array<string>,
        /**
         * A search term.
         */
        search?: string,
        /**
         * sport
         *
         * * `football` - Football
         * * `hockey` - Hockey
         */
        sport?: 'football' | 'hockey',
        /**
         * Multiple values may be separated by commas.
         */
        status?: Array<string>,
        /**
         * Multiple values may be separated by commas.
         */
        transferId?: Array<number>,
    }): CancelablePromise<PaginatedPortfolioListReadModelList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/transfers/fanvestor-portfolio/',
            query: {
                'club': club,
                'funding_period_max': fundingPeriodMax,
                'funding_period_max_gte': fundingPeriodMaxGte,
                'funding_period_max_lte': fundingPeriodMaxLte,
                'funding_period_min': fundingPeriodMin,
                'funding_period_min_gte': fundingPeriodMinGte,
                'funding_period_min_lte': fundingPeriodMinLte,
                'funding_type': fundingType,
                'limit': limit,
                'offset': offset,
                'ordering': ordering,
                'player_position': playerPosition,
                'position_type': positionType,
                'regions': regions,
                'search': search,
                'sport': sport,
                'status': status,
                'transfer_id': transferId,
            },
        });
    }
    /**
     * @returns FanVestorPortfolioFinancialInfo
     * @throws ApiError
     */
    public transfersFanvestorPortfolioFinancialInfoRetrieve({
        transferPk,
    }: {
        transferPk: number,
    }): CancelablePromise<FanVestorPortfolioFinancialInfo> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/transfers/fanvestor-portfolio/{transfer_pk}/financial-info/',
            path: {
                'transfer_pk': transferPk,
            },
        });
    }
    /**
     * @returns FanVestorPortfolioTotalPayout
     * @throws ApiError
     */
    public transfersFanvestorPortfolioTotalInvestmentRetrieve(): CancelablePromise<FanVestorPortfolioTotalPayout> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/transfers/fanvestor-portfolio/total-investment/',
        });
    }
    /**
     * @returns FanVestorPortfolioTotalPayout
     * @throws ApiError
     */
    public transfersFanvestorPortfolioTotalPayoutRetrieve(): CancelablePromise<FanVestorPortfolioTotalPayout> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/transfers/fanvestor-portfolio/total-payout/',
        });
    }
    /**
     * @returns PremiumChoices
     * @throws ApiError
     */
    public transfersPremiumsChoicesRetrieve({
        sport,
    }: {
        /**
         * Sport choices
         */
        sport: 'football' | 'hockey',
    }): CancelablePromise<PremiumChoices> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/transfers/premiums/{sport}/choices/',
            path: {
                'sport': sport,
            },
        });
    }
    /**
     * @returns REConditionChoice
     * @throws ApiError
     */
    public transfersRewardsConditionalChoicesList({
        rewardNameKey,
    }: {
        rewardNameKey: string,
    }): CancelablePromise<Array<REConditionChoice>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/transfers/rewards/{reward_name_key}/conditional-choices/',
            path: {
                'reward_name_key': rewardNameKey,
            },
        });
    }
    /**
     * @returns RewardChoices
     * @throws ApiError
     */
    public transfersRewardsChoicesRetrieve({
        sport,
    }: {
        /**
         * Sport choices
         */
        sport: 'football' | 'hockey',
    }): CancelablePromise<RewardChoices> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/transfers/rewards/{sport}/choices/',
            path: {
                'sport': sport,
            },
        });
    }
    /**
     * @returns RewardModifierChoices
     * @throws ApiError
     */
    public transfersRewardsModifiersChoicesRetrieve({
        sport,
    }: {
        /**
         * Sport choices
         */
        sport: 'football' | 'hockey',
    }): CancelablePromise<RewardModifierChoices> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/transfers/rewards/{sport}/modifiers-choices/',
            path: {
                'sport': sport,
            },
        });
    }
    /**
     * @returns SocialPerkChoices
     * @throws ApiError
     */
    public transfersSocialPerksChoicesRetrieve({
        sport,
    }: {
        /**
         * Sport choices
         */
        sport: 'football' | 'hockey',
    }): CancelablePromise<SocialPerkChoices> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/transfers/social-perks/{sport}/choices/',
            path: {
                'sport': sport,
            },
        });
    }
}
