import { createRouter, createWebHistory } from 'vue-router'

import { authMiddleware } from '@/modules/auth/middleware'
import authRoutes from '@/modules/auth/routes'
import clubRoutes from '@/modules/club/routes'
import fanvestorRoutes, { TEAM } from '@/modules/fanvestor/routes'
import Home from '@/modules/Home.vue'
import { SPORTS } from '@/utils/variables'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    ...authRoutes,
    ...fanvestorRoutes,
    ...clubRoutes,
    { path: '/500', component: () => import('./views/500.vue') },
    { path: '/', component: Home },
    {
      path: '/spvggbayreuth',
      redirect: {
        name: TEAM,
        params: { sport: SPORTS.FOOTBALL, fundingId: 55 },
        query: { referralBy: 20 },
      },
    },
  ],
})

router.beforeEach(authMiddleware)

export default router
